<template>
    <!--  -->
    <div class="comment__form">
        <div class="comment__form-top" v-if="$vuetify.breakpoint.width > 1240">
            <p style="display: flex; flex-flow:row; align-items: center;" v-if="!autorized">
                <b>
                    <auth-dialog class="col" style="padding: 0; margin: 0">
                        <template #activator="{ on, attrs }">
                            <b depressed class="main_color_text" plain v-bind="attrs" v-on="on">
                                {{ $t('blog.register') }}
                            </b>
                        </template>
                    </auth-dialog>
                </b>

                {{ $t('blog.or') }}

                <b>
                    <auth-dialog class="col" style="padding: 0; margin: 0" show_form_login="true">
                        <template #activator="{ on, attrs }">
                            <b depressed class="main_color_text" plain v-bind="attrs" v-on="on">
                                {{ $t('blog.login') }}
                            </b>
                        </template>
                    </auth-dialog>
                </b>
            </p>
        </div>
        <div style="flex-flow: column; margin-left: 0;" class="comment__form-top" v-else>
            <p style="display: flex; flex-flow:row; align-items: center; margin-bottom: 10px;" v-if="!autorized">
                <b>
                    <auth-dialog class="col" style="padding: 0; margin: 0">
                        <template #activator="{ on, attrs }">
                            <b depressed class="main_color_text" plain v-bind="attrs" v-on="on">
                                {{ $t('blog.register') }}
                            </b>
                        </template>
                    </auth-dialog>
                </b>

                {{ $t('blog.or') }}

                <b>
                    <auth-dialog class="col" style="padding: 0; margin: 0" show_form_login="true">
                        <template #activator="{ on, attrs }">
                            <b depressed class="main_color_text" plain v-bind="attrs" v-on="on">
                                {{ $t('blog.login') }}
                            </b>
                        </template>
                    </auth-dialog>
                </b>
            </p>
            <input v-model="username" type="text" name="text" id="" :placeholder="$t('blog.username')">
        </div>
        <textarea v-model="text" :placeholder="$t('blog.comment_text')" name="comment" id="" cols="30"
            rows="10"></textarea>
        <div class="comment__form-bottom">
            <div class=""></div>
            <button @click="handleResponse()"> {{ $t('blog.send') }} </button>
        </div>
    </div>
</template>

<script>
import BlogComment from "../../models/BlogComment";
import AuthDialog from "../dialogs/AuthDialog.vue";

export default {
    name: "BlogCommentForm",
    components: { AuthDialog },
    props: {
        parent_comment_id: {
        },
        blog_id: {
            required: true
        },
        updateComments: Function,
        updateReplies: Function

    },
    data: function () {
        return {
            // username: '',
            text: '',
            autorized: false
        }
    },
    methods: {
        async handleResponse() {
            if (!this.autorized) {
                alert('Авторизируйтесь')
                return
            }

            if (this.parent_comment_id === 0 || this.parent_comment_id == undefined) {
                const comment = new BlogComment({
                    username: this.username,
                    text: this.text,
                    user_id: this.$auth.user()?.id,
                    parent_comment_id: this.parent_comment_id ? this.parent_comment_id : null,
                    blog_id: this.blog_id,
                    is_reply: this.parent_comment_id ? 1 : 0
                })
                comment.save().then(() => this.updateComments());
            } else {
                const comment = new BlogComment({
                    username: this.username,
                    text: this.text,
                    user_id: this.$auth.user()?.id,
                    parent_comment_id: this.parent_comment_id ? this.parent_comment_id : 0,
                    blog_id: this.blog_id,
                    is_reply: this.parent_comment_id ? 1 : 0
                })
                comment.save().then(() => this.updateReplies());
            }
            this.username = ''
            this.text = ''
        }
    },
    mounted: function () {
        if (this.$auth.user().id) {
            this.autorized = true;
        }
    }
}
</script>

<style scoped lang="scss">
.comment__form {
    display: flex;
    flex-flow: column;
    padding: 38px;
    background-color: #fff;

    .comment__form-top {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-bottom: 23px;
        align-items: center;

        p {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 0;
            margin-left: 20px;
            cursor: pointer;
        }

        b {
            color: #3F44C3;
            margin-left: 5px;
            margin-right: 5px;
        }

        input {
            border: 1px solid #3F44C3;
            color: #3F44C3;
            font-size: 18px;
            box-sizing: border-box;
            width: 100%;
            padding-left: 16px;
            outline: none;
            height: 52px;
        }

        input::placeholder {
            color: #3F44C3;
            opacity: 0.57;
        }
    }

    textarea {
        border: 1px solid #3F44C3;
        padding: 16px;
        font-size: 18px;
        font-family: 400;
        color: #3F44C3;
        outline: none;
    }

    textarea::placeholder {
        color: #3F44C3;
        opacity: 0.57;
    }

    .comment__form-bottom {
        margin-top: 23px;
        display: flex;
        flex-flow: row;
        justify-content: space-between;

        button {
            border: 2px solid #3F44C3;
            color: #3F44C3;
            font-size: 18px;
            font-weight: 700;
            height: 49px;
            width: 190px;
        }

        button:hover {
            background-color: #3F44C3;
            color: #fff;
        }
    }
}
</style>
