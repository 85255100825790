import { render, staticRenderFns } from "./BlogCommentForm.vue?vue&type=template&id=714ec3c6&scoped=true&"
import script from "./BlogCommentForm.vue?vue&type=script&lang=js&"
export * from "./BlogCommentForm.vue?vue&type=script&lang=js&"
import style0 from "./BlogCommentForm.vue?vue&type=style&index=0&id=714ec3c6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "714ec3c6",
  null
  
)

export default component.exports