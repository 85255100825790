<template>
    <div class="comment-card">
        <p style="margin-bottom:0;">{{comment.username}}</p>
        <p style="color: #3F44C3;">
            {{ new Date(comment.created_at).getDate() }}

            {{ language == 'ru' ? months_ru[new Date(comment.created_at).getMonth()] : '' }}
            {{ language == 'en' ? months_en[new Date(comment.created_at).getMonth()] : '' }}
            {{ language == 'hy' ? months_hy[new Date(comment.created_at).getMonth()] : '' }}
        </p>

        <p style="margin-top: 27px;">{{ comment.text }}</p>
        <p style="margin-top: 27px;">{{ comment.user.name }}</p>

        <div class="comment-card__footer">
            <button @click="replying = !replying" style="margin-bottom: 0;"> {{ $t('blog.send') }} </button>
            <div class="comment-card__footer-likes">
                <img src="../../../../public/img/heart 1.png" alt="" srcset="" />
                <p style="margin-bottom: 0;">0</p>
            </div>
        </div>

        <blog-comment-form :updateReplies="updateReplies" :blog_id="blog_id" :parent_comment_id="comment.id"
            v-if="replying"></blog-comment-form>

        <div style="background-color: #f4f4f4;" v-for="reply in getCommentReplies" v-bind:key="reply.text" class="comment-card">
            <p style="color: #3F44C3;">
                {{ new Date(reply.created_at).getDate() }}

                {{ language == 'ru' ? months_ru[new Date(reply.created_at).getMonth()] : '' }}
                {{ language == 'en' ? months_en[new Date(reply.created_at).getMonth()] : '' }}
                {{ language == 'hy' ? months_hy[new Date(reply.created_at).getMonth()] : '' }}
            </p>
            <p style="margin-top: 27px; margin-bottom: 0;">{{ reply.text }}</p>
            <p style="margin-bottom:0; margin-top: 27px;">{{ reply.user.name }}</p>
        </div>
    </div>
</template>

<script>
import BlogComment from '../../models/BlogComment'
import BlogCommentForm from '../forms/BlogCommentForm.vue'

export default {
    name: "blog-comments-card",
    props: ['comment', 'blog_id', 'replies'],
    components: { BlogCommentForm },

    computed: {
      getCommentReplies(){
        return this.replies.filter(reply =>  reply.parent_comment_id === this.comment.id);
      }
    },

    data: function () {
        return {
            // replies: [],
            months_ru: ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'],
            months_en: ['January', 'February', 'March', 'April', 'May', 'June', 'Juny', 'August', 'September', 'October', 'November', 'December'],
            months_hy: ['հունվար', 'փետրվար', 'մարտ', 'ապրիլ', 'մայիս', 'հունիս', 'հուլիս', 'օգոստոս', 'սեպտեմբեր', 'հոկտեմբեր', 'նոյեմբեր', 'դեկտեմբեր'],
            locales: ['en', 'hy', 'ru'],
            language: 'ru',
            replying: false,
        }
    },
    methods: {
        async init() {
            const url = new URL(window.location.href)
            let hostname = url.hostname;
            let urlArray = hostname.split('.')
            if (this.locales.includes(urlArray[0])) {
                this.language = urlArray[0]
            } else {
                this.language = 'hy'
            }

            this.replies = await BlogComment.custom('/blogComments/replies/' + this.comment.id).get()
            this.sortComments()
        },
        async updateReplies() {
            this.replying = false;
            this.replies = await BlogComment.custom('/blogComments/replies/' + this.comment.id).get()
            this.sortComments()
        },
        sortComments() {
            this.replies = this.replies.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        },
    },
    mounted: async function () {
        await this.init()
    },
}
</script>

<style lang="scss" scoped>
.comment-card {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;

    p {
        font-size: 18px;
        font-weight: 400;
    }

    .comment-card__footer {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: end;
        margin-top: 26px;

        button {
            font-size: 18px;
            font-weight: 700;
            color: #3F44C3;
        }

        .comment-card__footer-likes {
            display: flex;
            flex-flow: row;

            p {
                font-size: 12px;
            }

            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
    }
}
</style>
