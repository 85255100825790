var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comment__form"},[(_vm.$vuetify.breakpoint.width > 1240)?_c('div',{staticClass:"comment__form-top"},[(!_vm.autorized)?_c('p',{staticStyle:{"display":"flex","flex-flow":"row","align-items":"center"}},[_c('b',[_c('auth-dialog',{staticClass:"col",staticStyle:{"padding":"0","margin":"0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('b',_vm._g(_vm._b({staticClass:"main_color_text",attrs:{"depressed":"","plain":""}},'b',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('blog.register'))+" ")])]}}],null,false,1032216996)})],1),_vm._v(" "+_vm._s(_vm.$t('blog.or'))+" "),_c('b',[_c('auth-dialog',{staticClass:"col",staticStyle:{"padding":"0","margin":"0"},attrs:{"show_form_login":"true"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('b',_vm._g(_vm._b({staticClass:"main_color_text",attrs:{"depressed":"","plain":""}},'b',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('blog.login'))+" ")])]}}],null,false,4033379118)})],1)]):_vm._e()]):_c('div',{staticClass:"comment__form-top",staticStyle:{"flex-flow":"column","margin-left":"0"}},[(!_vm.autorized)?_c('p',{staticStyle:{"display":"flex","flex-flow":"row","align-items":"center","margin-bottom":"10px"}},[_c('b',[_c('auth-dialog',{staticClass:"col",staticStyle:{"padding":"0","margin":"0"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('b',_vm._g(_vm._b({staticClass:"main_color_text",attrs:{"depressed":"","plain":""}},'b',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('blog.register'))+" ")])]}}],null,false,1032216996)})],1),_vm._v(" "+_vm._s(_vm.$t('blog.or'))+" "),_c('b',[_c('auth-dialog',{staticClass:"col",staticStyle:{"padding":"0","margin":"0"},attrs:{"show_form_login":"true"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('b',_vm._g(_vm._b({staticClass:"main_color_text",attrs:{"depressed":"","plain":""}},'b',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('blog.login'))+" ")])]}}],null,false,4033379118)})],1)]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],attrs:{"type":"text","name":"text","id":"","placeholder":_vm.$t('blog.username')},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.username=$event.target.value}}})]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],attrs:{"placeholder":_vm.$t('blog.comment_text'),"name":"comment","id":"","cols":"30","rows":"10"},domProps:{"value":(_vm.text)},on:{"input":function($event){if($event.target.composing){ return; }_vm.text=$event.target.value}}}),_c('div',{staticClass:"comment__form-bottom"},[_c('div',{}),_c('button',{on:{"click":function($event){return _vm.handleResponse()}}},[_vm._v(" "+_vm._s(_vm.$t('blog.send'))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }