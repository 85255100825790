<template>
    <front-layout>
        <v-container>
            <v-col class="blogpage">
                <v-row>
                    <v-img class="blogpage__img" :src="blog.main_img_url"
                        :height="$vuetify.breakpoint.width > 900 ? 400 : 200" alt="City photo">
                    </v-img>
                </v-row>
                <v-row class="blogpage__subtitle" v-if="$vuetify.breakpoint.width > 1000">

                    <p class="blogpage__subtitle-date">
                        {{ new Date(blog.created_at).getDate() }}

                        {{ language == 'ru' ? months_ru[new Date(blog.created_at).getMonth()] : '' }}
                        {{ language == 'en' ? months_en[new Date(blog.created_at).getMonth()] : '' }}
                        {{ language == 'hy' ? months_hy[new Date(blog.created_at).getMonth()] : '' }}
                    </p>

                    <div style="display: table; text-align: end; word-break: break-all;" class="blogpage__subtitle-news">

                        <a v-for="tag in tags" :key="tag.id">
                            {{ language == 'ru' ? tag.text_ru : '' }}
                            {{ language == 'en' ? tag.text_en : '' }}
                            {{ language == 'hy' ? tag.text_hy : '' }}
                        </a>

                    </div>
                </v-row>
                <v-row class="blogpage__subtitle" style="flex-flow: column; margin-top: 15px !important;" v-else>
                    <div style="display: table;" class="blogpage__subtitle-news">

                        <a style=" font-size: 16px;" v-for="tag in tags" :key="tag.id">
                            {{ language == 'ru' ? tag.text_ru : '' }}
                            {{ language == 'en' ? tag.text_en : '' }}
                            {{ language == 'hy' ? tag.text_hy : '' }}
                        </a>

                    </div>

                    <p style="font-size: 16px;" class="blogpage__subtitle-date">
                        {{ new Date(blog.created_at).getDate() }}

                        {{ language == 'ru' ? months_ru[new Date(blog.created_at).getMonth()] : '' }}
                        {{ language == 'en' ? months_en[new Date(blog.created_at).getMonth()] : '' }}
                        {{ language == 'hy' ? months_hy[new Date(blog.created_at).getMonth()] : '' }}
                    </p>

                </v-row>
                <v-row>

                    <h1>
                        {{ language == 'ru' ? blog.title_ru : '' }}
                        {{ language == 'en' ? blog.title_en : '' }}
                        {{ language == 'hy' ? blog.title_hy : '' }}
                    </h1>

                </v-row>
                <v-row class="blogpage__text">
                    <p> <img :src="blog.text_img_url" v-if="blog.text_img_url" alt="City photo" />
                        {{ language == 'ru' ? blog.text_ru : '' }}
                        {{ language == 'en' ? blog.text_en : '' }}
                        {{ language == 'hy' ? blog.text_hy : '' }}
                    </p>
                </v-row>
            </v-col>
        </v-container>
        <!-- Скрытый блок с карточками с квартирами
        <div style="display: none; background-color: #f4f4f4;" v-if="$vuetify.breakpoint.width < 1240">
            <v-container style="overflow: hidden;" class="developers-wrapper">
                <input type="radio" name="slider" id="slide1" checked>
                <input type="radio" name="slider" id="slide2">
                <input type="radio" name="slider" id="slide3">
                <input type="radio" name="slider" id="slide4">
                <v-col id="slides" style="padding: 0;">
                    <h2 style="margin-bottom: 20px; margin-top: 20px;">Квартиры от застройщиков с акциями</h2>
                    <div class="developers">
                        <v-col style="padding: 0; margin-right: 20px;" class="developercard" cols="3"
                            v-for="developer in developers" :key="developer.id">
                            <v-img class="developercard__header"
                                :src="`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFo0Q9hi3qIytltJwWJBOks0H2zFuvty6ifw&usqp=CAU`"
                                :height="$vuetify.breakpoint.width > 900 ? 400 : 200" alt="City photo">
                                <div class="developercard__header-top">
                                    <div class="developercard__header-top-rating">
                                        <div class="developercard__header-top-stars">
                                            <img src="../../../../../public/img/Star 1.png" alt="" srcset="">
                                            <p>4.5</p>
                                        </div>
                                        <div class="developercard__header-top-comments">
                                            <img src='../../../../../public/img/chat 1.png' alt="" srcset="">
                                            <p>11</p>
                                        </div>
                                    </div>
                                    <div class="developercard__header-top-phone">
                                        <p>
                                            <v-img class="developercard__header-top-phone-icon"
                                                src="../../../../../public/img/phone-call 1.png">
                                            </v-img>
                                        </p>
                                    </div>
                                </div>
                                <div class="developercard__header-bottom">
                                    <div class="developercard__header-bottom-precent">%</div>
                                </div>
                            </v-img>
                            <div class="developercard__subtitle">
                                <p>Setl Group</p>
                                <p class="developercard__subtitle-stage">Строится</p>
                            </div>
                            <h3>ЖК "Golden City"</h3>
                            <p class="developercard__subtitle-adress">СПБ, Василеостровский р-н, <br> Наличная улица 42
                            </p>
                            <div class="developercard__rooms">
                                <p>1-комн.</p>
                                <p style="opacity: 0.5;">от 37.90 м<sup>2</sup></p>
                                <p>от 11 104 070 Р</p>
                            </div>
                            <div class="developercard__rooms">
                                <p>2-комн.</p>
                                <p style="opacity: 0.5;">от 37.90 м<sup>2</sup></p>
                                <p>от 15 104 070 Р</p>
                            </div>
                            <div class="developercard__rooms">
                                <p>3-комн.</p>
                                <p style="opacity: 0.5;">от 37.90 м<sup>2</sup></p>
                                <p>от 18 104 070 Р</p>
                            </div>
                            <div class="developercard__rooms">
                                <p>4-комн.</p>
                                <p style="opacity: 0.5;">от 37.90 м<sup>2</sup></p>
                                <p>от 27 104 070 Р</p>
                            </div>
                            <div class="developercard__footer">
                                <p class="developercard__footer-apart">123 квартиры</p>
                                <p class="developercard__footer-month">от 58 800 руб/мес</p>
                            </div>
                        </v-col>
                    </div>
                    <div id="controls">
                        <label for="slide1"></label>
                        <label for="slide2"></label>
                        <label for="slide3"></label>
                        <label for="slide4"></label>
                    </div>
                    <p class="showmore">Показать еще</p>
                </v-col>
            </v-container>
        </div>
        <div style="display: none; background-color: #f4f4f4;" v-else>
            <v-container style="overflow: hidden;" class="developers-wrapper">
                <v-col id="slides" style="padding: 0;">
                    <h2 style="margin-bottom: 20px; margin-top: 20px;">Квартиры от застройщиков с акциями</h2>
                    <div class="developers" style="justify-content: space-between;">
                        <v-col style="padding: 0;" class="developercard" cols="3" v-for="developer in developers"
                            :key="developer.id">
                            <v-img class="developercard__header"
                                :src="`https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFo0Q9hi3qIytltJwWJBOks0H2zFuvty6ifw&usqp=CAU`"
                                :height="$vuetify.breakpoint.width > 900 ? 400 : 200" alt="City photo">
                                <div class="developercard__header-top">
                                    <div class="developercard__header-top-rating">
                                        <div class="developercard__header-top-stars">
                                            <img src="../../../../../public/img/Star 1.png" alt="" srcset="">
                                            <p>4.5</p>
                                        </div>
                                        <div class="developercard__header-top-comments">
                                            <img src="../../../../../public/img/chat 1.png" alt="" srcset="">
                                            <p>11</p>
                                        </div>
                                    </div>
                                    <div class="developercard__header-top-phone">
                                        <p>
                                            <v-img class="developercard__header-top-phone-icon"
                                                src="../../../../../public/img/phone-call 1.png">
                                            </v-img>
                                        </p>
                                    </div>
                                </div>
                                <div class="developercard__header-bottom">
                                    <div class="developercard__header-bottom-precent">%</div>
                                </div>
                            </v-img>
                            <div class="developercard__subtitle">
                                <p>Setl Group</p>
                                <p class="developercard__subtitle-stage">Строится</p>
                            </div>
                            <h3>ЖК "Golden City"</h3>
                            <p class="developercard__subtitle-adress">СПБ, Василеостровский р-н, <br> Наличная улица 42
                            </p>
                            <div class="developercard__rooms">
                                <p>1-комн.</p>
                                <p style="opacity: 0.5;">от 37.90 м<sup>2</sup></p>
                                <p>от 11 104 070 Р</p>
                            </div>
                            <div class="developercard__rooms">
                                <p>2-комн.</p>
                                <p style="opacity: 0.5;">от 37.90 м<sup>2</sup></p>
                                <p>от 15 104 070 Р</p>
                            </div>
                            <div class="developercard__rooms">
                                <p>3-комн.</p>
                                <p style="opacity: 0.5;">от 37.90 м<sup>2</sup></p>
                                <p>от 18 104 070 Р</p>
                            </div>
                            <div class="developercard__rooms">
                                <p>4-комн.</p>
                                <p style="opacity: 0.5;">от 37.90 м<sup>2</sup></p>
                                <p>от 27 104 070 Р</p>
                            </div>
                            <div class="developercard__footer">
                                <p class="developercard__footer-apart">123 квартиры</p>
                                <p class="developercard__footer-month">от 58 800 руб/мес</p>
                            </div>
                        </v-col>
                    </div>
                    <p class="showmore">Показать еще</p>
                </v-col>
            </v-container>
        </div>
                                    -->
        <div style="background-color: #f4f4f4;">
            <v-container>
                <h3>{{ $t('blog.comments') }}</h3>
                <v-row class="comment">
                    <v-col cols="12">
                        <blog-comment-form :updateComments="updateComments" :blog_id="id"></blog-comment-form>

                        <div v-for="comment in blogComments" v-bind:key="comment.id">
                            <blog-comment-card v-if="comment.parent_comment_id == null" :blog_id="id" :comment="comment" :replies="replies"/>
                        </div>

                    </v-col>
                    <!-- Скрытый блок с жк
                    <v-col cols="2" class="blog__adv-container" style="display:none;"
                        v-if="$vuetify.breakpoint.width > 1240">
                        <div class="blog__adv">
                            <v-img :src="`https://www.ccnova.ru/images/blog/sovremennye-doma/sovremennyj-dom-4.jpg`">
                            </v-img>
                            <div class="blog__adv-content">
                                <h1>ЖК "Golden City"</h1>
                                <p class="blog__adv-content-text">Lorem ipsum dolor sit amet consectetur adipisicing
                                    elit. Nihil vel dolorem quas
                                    praesentium! Sit repudiandae eligendi, hic assumenda odio nobis deserunt officia eos
                                    doloremque distinctio tenetur amet quisquam, ab nam?
                                </p>
                                <p class="blog__adv-content-bottom">4 quarter 2022</p>
                            </div>
                        </div>
                    </v-col>
                    -->
                </v-row>
            </v-container>
        </div>
    </front-layout>
</template>

<script>
import FrontLayout from '../../../layouts/FrontLayout.vue';
import Blog from '../../../models/Blog';
import BlogCommentCard from '../../../components/cards/BlogCommentCard.vue';
import BlogCommentForm from '../../../components/forms/BlogCommentForm.vue';
import BlogComment from '../../../models/BlogComment';
import BlogTag from "../../../models/BlogTag";


export default {
    name: "blogs.show",
    components: {
        FrontLayout,
        BlogCommentCard,
        BlogCommentForm,
        //Advertising: () => import('@/components/ads/Advertising'),
    },
    data: function () {
        return {
            id: this.$route.params['blog'],
            blog: {},
            tags: [],
            blogComments: [],
            months_ru: ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'],
            months_en: ['January', 'February', 'March', 'April', 'May', 'June', 'Juny', 'August', 'September', 'October', 'November', 'December'],
            months_hy: ['հունվար', 'փետրվար', 'մարտ', 'ապրիլ', 'մայիս', 'հունիս', 'հուլիս', 'օգոստոս', 'սեպտեմբեր', 'հոկտեմբեր', 'նոյեմբեր', 'դեկտեմբեր'],
            locales: ['en', 'hy', 'ru'],
            language: 'ru',
            replies: [],
            blog_name:''
        }
    },
    metaInfo() {
        return {
            title: this.$t('meta.blog_title', {blog_name: this.blog_name}),
            meta: [
                { name: 'description', content: this.$t('meta.blog_description', {blog_name: this.blog_name}) }
            ]
        }
    },
    methods: {
        async init() {
            const url = new URL(window.location.href)
            let hostname = url.hostname;
            let urlArray = hostname.split('.')
            if (this.locales.includes(urlArray[0])) {
                this.language = urlArray[0]
            } else {
                this.language = 'hy'
            }

            if (this.id !== undefined) {
                this.blog = await Blog.custom('/blog/id/' + this.id).first()
                this.setBlogName()
                this.tags = await BlogTag.custom('/blogTags/id/' + this.blog.id).get()
                this.blogComments = await BlogComment.custom('/blogComments/id/' + this.id).get()
                this.sortComments()
            }

            this.replies = this.blogComments.filter(comment => comment.parent_comment_id != null)
        },
        setBlogName(){
            if(this.$i18n.locale === 'ru'){
                this.blog_name = this.blog.title_ru
            }else if(this.$i18n.locale === 'en'){
                this.blog_name = this.blog.title_en
            }else {
                this.blog_name = this.blog.title_hy
            }
        },
        chunkArray(arr, qty) {
            return arr.reduce((prev, cur, i, a) => !(i % qty) ? prev.concat([a.slice(i, i + qty)]) : prev, []);
        },
        async updateComments() {
            this.blogComments = await BlogComment.custom('/blogComments/id/' + this.id).get()
            this.sortComments()
        },
        sortComments() {
            this.blogComments = this.blogComments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        },
    },
    mounted: async function () {
        await this.init()
    }
}
</script>
<style lang="scss" scoped>
.blogpage {
    .blogpage-title {
        display: flex;
        flex-flow: row;
        text-align: center;
        align-items: center;

        .blogpage-title-text {
            margin-right: 5px;
            margin-left: 5px;
            color: #3F44C3;
            font-size: 16px;
            font-weight: 500;
            opacity: 1;
        }

        .blogpage-title-text:first-child {
            margin-left: 0;
        }

        .blogpage-title-direct {
            margin-left: 5px;
        }
    }

    h1 {
        font-size: 48px;
        margin-top: 10px;
        margin-bottom: 22px;
    }

    .blogpage__subtitle {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        font-size: 24px;
        font-weight: 500;
        color: #3F44C3;

        p {
            margin-top: 16px;
        }

        .blogpage__subtitle-news {
            margin-top: 16px;

            a {
                margin-left: 30px;
            }
        }
    }
}

.blogpage__text {
    word-break: break-all;

    p {
        font-size: 16px;
    }

    img {
        float: left;
        margin-right: 20px;
        margin-bottom: 20px;
        max-width: 700px;
    }

    @media screen and (max-width: 1240px) {
        img {
            width: 500px;
        }
    }

    @media screen and (max-width: 900px) {
        img {
            width: 400px;
        }
    }

    @media screen and (max-width: 700px) {
        img {
            width: 300px;
        }
    }

    @media screen and (max-width: 600px) {
        img {
            width: 100%;
            float: none;
            margin-right: 0;
        }
    }
}

.showmore {
    color: #3F44C3;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.developers-wrapper {
    input[ type='radio'] {
        display: none;
    }
}

.developers {
    display: flex;
    flex-flow: row;
    margin-bottom: 30px;
    overflow: hidden;
    transition: 0.1s all;

    .developercard {
        position: relative;
        display: flex;
        flex-flow: column;
        max-height: 515px;
        min-height: 515px;
        min-width: 295px;
        max-width: 295px;
        background-color: #fff;


        .developercard__header {
            position: relative;
            margin-bottom: 9px;
            max-height: 200px;
            max-width: 295px;

            .developercard__header-top {
                top: 0;
                display: flex;
                flex-flow: row;
                justify-content: space-between;

                .developercard__header-top-rating {
                    display: flex;
                    flex-flow: row;
                    justify-content: space-between;
                    background-color: rgba(0, 0, 0, 0.7);
                    margin-top: 8px;
                    margin-left: 6px;
                    height: 38px;
                    width: 109px;
                    align-items: center;

                    p {
                        margin: 0;
                    }

                    .developercard__header-top-stars {
                        display: flex;
                        flex-flow: row;
                        color: #fff;
                        align-items: center;

                        img {
                            height: 17px;
                            width: 17px;
                            margin-left: 6px;
                            margin-right: 7px;
                        }
                    }

                    .developercard__header-top-comments {
                        display: flex;
                        flex-flow: row;
                        color: #fff;
                        margin-right: 8px;
                        align-items: center;

                        img {
                            height: 17px;
                            width: 17px;
                            margin-right: 7px;
                        }
                    }
                }

                .developercard__header-top-phone {
                    width: 38px;
                    height: 38px;
                    background-color: #fff;
                    margin-top: 8px;
                    margin-right: 9px;
                    display: flex;
                    align-items: center;

                    p {
                        margin: 0;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    .developercard__header-top-phone-icon {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .developercard__header-bottom {
                .developercard__header-bottom-precent {
                    position: absolute;
                    bottom: 0px;
                    right: 0px;
                    width: 39px;
                    height: 39px;
                    background-color: #00BC35;
                    color: white;
                    font-size: 24px;
                    font-weight: 700;
                    text-align: center;
                    margin-right: 9px;
                    margin-bottom: 7px;
                }
            }
        }

        h3 {
            margin-left: 5px;
            color: #3F44C3;
            font-size: 24px;
            font-weight: 500;
        }

        .developercard__subtitle {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            margin-left: 6px;
            margin-right: 9px;

            p {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 8px;
            }

            .developercard__subtitle-stage {
                color: #00BC35;
            }
        }

        .developercard__subtitle-adress {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            margin-left: 6px;
        }

        .developercard__rooms {
            background-color: rgba(63, 68, 195, 0.1);
            margin-bottom: 5px;
            display: flex;
            flex-flow: row;
            justify-content: space-evenly;
            text-align: center;
            align-items: center;
            height: 37px;

            p {
                margin: 0;
            }
        }

        .developercard__footer {
            display: flex;
            flex-flow: row;
            justify-content: space-between;
            margin-left: 6px;
            margin-right: 9px;
            margin-top: 5px;

            p {
                margin-bottom: 10px;
            }

            .developercard__footer-apart {
                font-weight: 700;
                color: #3F44C3;
                font-size: 14px;
                margin-left: 6px;
            }

            .developercard__footer-month {
                font-weight: 400;
                color: #3F44C3;
                font-size: 14px;

            }
        }
    }
}



.outer-text {
    p {
        font-size: 16px;
    }
}




h3 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 14px;
    margin-left: 6px;
    margin-top: 17px;
    margin-bottom: -4px;
}


.blog__adv {
    background-color: #fff;
    width: 295px;

    .blog__adv-content {
        padding: 21px;

        .blog__adv-content-bottom {
            color: #3F44C3;
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            padding: 0;
        }

        .blog__adv-content {
            h1 {
                color: #000;
                font-weight: 500;
                font-size: 24px;
            }
        }
    }
}

#controls {
    text-align: center;
    margin-bottom: 40px;

    label {
        display: inline-block;
        width: 13px;
        height: 13px;
        border-radius: 100%;
        background: rgba(63, 68, 196, 0.1);
        margin: 0 10px;
        cursor: pointer;
    }
}

#slide1:checked~#slides .developers {
    margin-left: 0;
}

#slide2:checked~#slides .developers {
    margin-left: -295px;
}

#slide3:checked~#slides .developers {
    margin-left: -590px;
}

#slide4:checked~#slides .developers {
    margin-left: -885px;
}

#slide1:checked~#slides label:nth-child(1),
#slide2:checked~#slides label:nth-child(2),
#slide3:checked~#slides label:nth-child(3),
#slide4:checked~#slides label:nth-child(4) {
    background: #3F44C4;
}

@media screen and (max-width: 1240px) {
    .blogpage {
        .blogpage__subtitle-news {
            display: flex;
            margin-top: 0 !important;
            align-items: center;

            a:nth-child(1) {
                margin-left: 0;
            }
        }

        h1 {
            font-size: 36px;
        }
    }
}
</style>
